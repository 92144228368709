/* eslint-disable no-case-declarations */
import { Image } from '../../server'
import { flatData } from '../flat/flats_data'
import { Flat_Wrapper, Flat_Wrapper_Levels } from '../flat/flat_class_Wrapped'

/* In progress */
export enum MainHouse_Wrapper {
    HeadInfo = 0,
    Descrb,
    TextSep,
    SthElse,
    Carousel,
    BottomSthElse,
    FlatList,
    BottomSep,
    HeroList,
}

export function getMainHouseInfoWrapped(data: MainHouse_Wrapper) {
    switch (data) {
        case MainHouse_Wrapper.HeadInfo:
            return {
                head: {
                    title: ['Haupthaus'],
                    titleSlash: true,
                    links: [
                        {
                            text: 'Exklusives Wohnen im Haupthaus',
                            to: '/wohnungen/haupthaus',
                            slash: false,
                        },
                    ],
                    subTitle: ['Traumhafte Wohnungen im Hauptgebäude <br/>- großzügig und hochmodern!'],
                },
                image: {
                    name: 'main_1',
                    folder: ['imgs', 'root'],
                    alt: 'Konzeptbild',
                },
                content: {
                    banner: ['ab 76m²', 'bis 109m²'],
                    text: [
                        `Willkommen im Haupthaus des Alten Fährhofs! Dieses historische Gebäude wurde liebevoll saniert und begeistert mit seiner exklusiven Lage direkt an der malerischen Saale. 
                        Insgesamt finden Sie hier sieben einzigartige Wohnungen, die Ihnen einen traumhaften Blick auf die Saale bieten. 
                        Jede Wohnung wurde mit besonderer Sorgfalt gestaltet, um historischen Charme mit modernem Komfort zu vereinen. 
                        Ein besonderes Highlight ist das Torbogenzimmer, das mit seinem atemberaubenden Rundumblick beeindruckt. 
                        Erleben Sie das einzigartige Flair des Haupthauses und lassen Sie sich von den Wohnungen inspirieren.`,
                    ],
                    // link: {
                    //     text: 'Direkt zu den Wohnungen',
                    //     link: 'wohnungen',
                    //     scrollTo: true,
                    // },
                },
            }
        case MainHouse_Wrapper.Descrb:
            return {
                image: {
                    name: 'img_15_ausblick',
                    folder: ['imgs', 'flats', 'flat_12'],
                    alt: 'Konzeptbild',
                },
                content: [
                    'Das Haupthaus ',
                    '- Unvergleichliche Blicke auf die Saale',
                    `Die Wohnungen befinden sich über drei Stockwerke hinweg verteilt und bieten großzügige Wohnflächen für ein komfortables Wohngefühl. 
                    Jede Etage präsentiert ihren eigenen Charme und Stil, sodass Sie eine Vielzahl von Optionen zur Auswahl haben. 
                    Erleben Sie moderne Ausstattung und hohen Wohnkomfort, während Sie gleichzeitig die atemberaubende Aussicht auf die Saale von Ihrem eigenen Wohnraum aus genießen können.`,
                ],
            }
        case MainHouse_Wrapper.TextSep:
            return {
                content: ['Durch die Nähe der Saale und des Kurparks bieten sich wundervolle Wege und Möglichkeiten die Natur zu erleben.'],
                link: { text: 'Erkunde die Lage', link: '', scrollTo: false },
            }
        case MainHouse_Wrapper.SthElse:
            return {
                head: {
                    title: ['Noch nicht das Richtige gefunden?'],
                },
                choices: [
                    {
                        content: 'Gucken Sie auch an der Villa Leuna vorbei.',
                        link: 'https://villa-leuna.de/',
                    },
                    {
                        content: 'Gucken Sie auch an der Villa Arbor vorbei.',
                        link: 'https://villa-arbor.de/',
                    },
                    {
                        content: 'Gucken Sie auch an der Villa Münster vorbei.',
                        link: 'https://villa-münster.de/',
                    },
                ],
            }
        case MainHouse_Wrapper.Carousel:
            const _images = []
            const list = 21
            const skipNumbers = [1, 2, 13, 9, 10, 5, 20, 18]
            _images.push({
                name: `img_4_schlaf`,
                folder: ['imgs', 'flats', 'flat_11'],
                alt: 'Alter Fährhof',
            })
            _images.push({
                name: `img_10_wohn`,
                folder: ['imgs', 'flats', 'flat_11'],
                alt: 'Alter Fährhof',
            })
            _images.push({
                name: `img_4_flur`,
                folder: ['imgs', 'flats', 'flat_12'],
                alt: 'Alter Fährhof',
            })
            _images.push({
                name: `img_6_bad`,
                folder: ['imgs', 'flats', 'flat_12'],
                alt: 'Alter Fährhof',
            })
            _images.push({
                name: `img_13_kueche`,
                folder: ['imgs', 'flats', 'flat_12'],
                alt: 'Alter Fährhof',
            })

            _images.push({
                name: `img_8_bad`,
                folder: ['imgs', 'flats', 'flat_10'],
                alt: 'Alter Fährhof',
            })

            for (let i = 1; i < list + 1; i++) {
                if (!skipNumbers.includes(i)) {
                    _images.push({
                        name: `main_${i}`,
                        folder: ['imgs', 'root'],
                        alt: `Alter Fährhof main_${i}`,
                    })
                }
            }

            return {
                head: {
                    title: ['Bilder?'],
                },
                images: _images,
            }

        case MainHouse_Wrapper.BottomSthElse:
            return {
                title: ['Noch nicht das Richtige gefunden?'],
                links: [
                    {
                        text: 'Erkunde die Reihenhäuser ',
                        link: '/wohnungen/reihenhaus',
                        scrollTo: false,
                    },
                ],
                image: {
                    name: 'terraced_3',
                    folder: ['imgs', 'root'],
                    alt: 'Alter Fährhof von Oben',
                },
            }
        case MainHouse_Wrapper.FlatList:
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const _flats_EGFloor: any[] = []
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const _flats_OGFloor: any[] = []
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const _flats_DGFloor: any[] = []
            // eslint-disable-next-line no-lone-blocks
            {
                for (let k = 7; k < 13; k++) {
                    const data: Flat_Wrapper = flatData[k]
                    const newFlat: {
                        number: number
                        price: { main: number; partial: number }
                        size: number
                        available: boolean
                        rooms: { living: number; baths: number; sleep: number }
                        levels: { wLevels: Flat_Wrapper_Levels[]; nMain: number }
                        entrance: string
                        outSideExtra: string
                        blueprint: Image[]
                        flatListInfo?: string
                    } = {
                        number: data.flatNumber,
                        price: data.price,
                        size: data.size,
                        available: data.availability,
                        rooms: data.rooms,
                        levels: data.levels,
                        entrance: data.entrance,
                        outSideExtra: data.outSideExtra,
                        blueprint: data.blueprint,
                        flatListInfo: data.flatListInfo,
                    }

                    if (newFlat.levels.wLevels[0] === Flat_Wrapper_Levels.Erdgeschoss) {
                        _flats_EGFloor.push(newFlat)
                    } else if (newFlat.levels.wLevels[0] === Flat_Wrapper_Levels.Obergeschoss) {
                        _flats_OGFloor.push(newFlat)
                    } else {
                        _flats_DGFloor.push(newFlat)
                    }
                }
            }

            return {
                content: [
                    'Wohneinheiten',
                    'im Haupthaus',
                    'Im Haupthaus erwarten Sie insgesamt sieben charmante Wohneinheiten, die sich auf zwei Wohnungen pro Etage verteilen. ',
                    `Durch das zentrale Treppenhaus gelangen Sie bequem zu den einzelnen Wohnungen. 
                    Entdecken Sie die Vielfalt der Wohnungen und finden Sie Ihren ganz persönlichen Rückzugsort direkt an der Saale.`,
                ],
                image: {
                    name: 'img_6_bad',
                    folder: ['imgs', 'flats', 'flat_12'],
                    alt: 'Alter Fährhof von Oben',
                },
                mainLink: '/wohnungen/haupthaus',
                type: 'haupthaus',
                flats: [_flats_EGFloor, _flats_OGFloor, _flats_DGFloor],
            }

        case MainHouse_Wrapper.HeroList:
            const _free_flats: any[] = []

            for (let k = 7; k < 13; k++) {
                if (flatData[k].availability === false) continue
                const data: Flat_Wrapper = flatData[k]
                const newFlat: {
                    number: number
                    price: { main: number; partial: number }
                    size: number
                    available: boolean
                    rooms: { living: number; baths: number; sleep: number }
                    levels: { wLevels: Flat_Wrapper_Levels[]; nMain: number }
                    entrance: string
                    outSideExtra: string
                    blueprint: Image[]
                    flatListInfo?: string
                    image: {
                        name: string
                        folder: string[]
                    }
                } = {
                    number: data.flatNumber,
                    price: data.price,
                    size: data.size,
                    available: data.availability,
                    rooms: data.rooms,
                    levels: data.levels,
                    entrance: data.entrance,
                    outSideExtra: data.outSideExtra,
                    blueprint: data.blueprint,
                    flatListInfo: data.flatListInfo,
                    image: data.headImage,
                }
                _free_flats.push(newFlat)
            }

            return {
                content: ['Verfügbare Wohnungen', 'im Haupthaus'],
                // image: {
                //     name: 'img_6_bad',
                //     folder: ['imgs', 'flats', 'flat_12'],
                //     alt: 'Alter Fährhof von Oben',
                // },
                mainLink: '/wohnungen/haupthaus',
                type: 'haupthaus',
                flats: _free_flats,
            }

        case MainHouse_Wrapper.BottomSep:
            return {
                content: [
                    `Entdecken Sie das <strong>Torbogenzimmer</strong> - Ihre exklusive Residenz im Herzen des Alten Fährhofs! ` +
                        `Mit seiner erstklassigen Lage, ` +
                        `atemberaubendem Blick auf die Saale und den zentralen Innenhof bietet es eine einzigartige Wohnmöglichkeit für Alleinstehende und junge Erwachsene. ` +
                        `Klicken Sie hier, um mehr über das Torbogenzimmer zu erfahren und Ihr neues Zuhause zu entdecken!`,
                ],
                link: {
                    text: 'Zum Torbogenzimmer',
                    link: '/wohnungen/torbogenzimmer',
                    scrollTo: false,
                },
            }
    }
}
